import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import "./PostList.scss"

export default class IndexPage extends React.Component {
  render() {
    const { posts, title } = this.props

    return (
      <section className="container page-wrapper">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content">
              <h1 className="has-text-weight-bold is-size-2 has-text-centered">
                {title}
              </h1>
              <hr className="is-6" />
            </div>
          </div>
        </div>
        <div className="columns">
          {posts.map(({ node: post }) => (
            <div className="column is-4 post-wrapper content" key={post.id}>
              <h2 className="has-text-weight-bold is-size-3">
                <Link
                  to={`/${post.categories[0].slug}/${post.slug}`}
                  dangerouslySetInnerHTML={{
                    __html: post.title,
                  }}
                  className="has-text-dark"
                ></Link>
              </h2>
              <p>
                <strong>{post.date}</strong>
              </p>
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: post.excerpt.replace(/<p class="link-more.*/, ""),
                  }}
                />
                <Link
                  className="button is-link"
                  to={`/${post.categories[0].slug}/${post.slug}`}
                >
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    title
    excerpt
    date(formatString: "MMMM DD, YYYY")
    slug
    categories {
      slug
    }
  }
`
